<template>
    <v-container>
        <v-row>
            <v-col>
                <h1 class="text-h1">Verbeterplan indienen</h1>
            </v-col>
        </v-row>

        <v-form
            ref="form"
            v-model="isValid"
            class="pb-3"
        >
            <v-row>
                <v-col cols="12" md="6" class="pb-0">
                    <v-text-field
                        v-model="title"
                        label="Omschrijving"
                        required
                        :rules="requiredRules"
                        :disabled="isSubmitting"
                        outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                    <v-select
                        :items="projects"
                        v-model="project"
                        :disabled="isSubmitting"
                        label="Project"
                        outlined
                        item-value="id"
                    >
                        <template slot="selection" slot-scope="data">
                            {{ data.item.project_no }} - {{ data.item.name }}
                        </template>
                        <template slot="item" slot-scope="data">
                            {{ data.item.project_no }} - {{ data.item.name }}
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="12" class="pb-0">
                    <v-select
                        :items="departments"
                        v-model="department"
                        :disabled="isSubmitting"
                        label="Ingediend door afdeling"
                        outlined
                    ></v-select>
                </v-col>
<!--                Uitgeschakeld voor het indienen-->
<!--                <v-col cols="12" md="6" class="pb-0">-->
<!--                    <v-select-->
<!--                        :items="users"-->
<!--                        v-model="assignedUser"-->
<!--                        :disabled="isSubmitting"-->
<!--                        clearable-->
<!--                        label="Ondergebracht bij persoon"-->
<!--                        outlined-->
<!--                        item-text="title"-->
<!--                        item-value="id"-->
<!--                    ></v-select>-->
<!--                </v-col>-->
<!--                <v-col cols="12" md="6" class="pb-0">-->
<!--                    <v-menu-->
<!--                        ref="menu"-->
<!--                        v-model="menu"-->
<!--                        :close-on-content-click="false"-->
<!--                        transition="scale-transition"-->
<!--                        offset-y-->
<!--                        max-width="290px"-->
<!--                        min-width="auto"-->
<!--                    >-->
<!--                        <template v-slot:activator="{ on, attrs }">-->
<!--                            <v-text-field-->
<!--                                v-model="dateFormatted"-->
<!--                                label="Terugkop indiener"-->
<!--                                persistent-hint-->
<!--                                prepend-icon="fal fa-calendar"-->
<!--                                v-bind="attrs"-->
<!--                                v-on="on"-->
<!--                                :disabled="isSubmitting"-->
<!--                            ></v-text-field>-->
<!--                        </template>-->
<!--                        <v-date-picker-->
<!--                            v-model="terugkopIndiener"-->
<!--                            no-title-->
<!--                            @input="menu = false"-->
<!--                        ></v-date-picker>-->
<!--                    </v-menu>-->
<!--                </v-col>-->
                <v-col cols="12" md="6" class="pb-0">
                    <v-textarea
                        label="Wat is het probleem?"
                        v-model="problem"
                        required
                        :rules="requiredRules"
                        :disabled="isSubmitting"
                        outlined
                    ></v-textarea>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                    <v-textarea
                        label="Wat is het doel?"
                        v-model="purpose"
                        required
                        :rules="requiredRules"
                        :disabled="isSubmitting"
                        outlined
                    ></v-textarea>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                    <v-textarea
                        label="Wat is de oplossing?"
                        v-model="solution"
                        required
                        :rules="requiredRules"
                        :disabled="isSubmitting"
                        outlined
                    ></v-textarea>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                    <v-textarea
                        label="Hoe gaan we dit borgen?"
                        v-model="secure"
                        required
                        :rules="requiredRules"
                        :disabled="isSubmitting"
                        outlined
                    ></v-textarea>
                </v-col>
                <v-col cols="12" md="12" class="pb-0">
                    <v-file-input
                        label="Bijlage"
                        v-model="image"
                        prepend-icon="fal fa-paperclip"
                    ></v-file-input>
                </v-col>
                <v-col cols="12">
                    <v-file-input
                        label="Extra bijlagen"
                        v-model="extraFiles"
                        :multiple="true"
                        prepend-icon="fal fa-paperclip"
                    ></v-file-input>
                    <v-file-input
                        label="Extra afbeeldingen"
                        v-model="extraImages"
                        :multiple="true"
                        prepend-icon="fal fa-camera"
                        accept="image/*"
                    ></v-file-input>
                </v-col>
            </v-row>
            <v-btn
                class="btn-decorative"
                :disabled="!isValid || isSubmitting"
                :loading="isSubmitting"
                @click="submit"
                elevation="0"
            >
                <v-icon
                    small
                    class="mr-2"
                >
                    fal fa-arrow-right
                </v-icon>
                Indienen
            </v-btn>
        </v-form>
        <hr />
        <BackButton :route="{name: 'ImprovementList'}" />
    </v-container>
</template>

<script>
import BackButton from "../components/BackButton";
import {mapState} from "vuex";

export default {
    name: "ImprovementSubmit",
    components: {
        BackButton,
    },
    data: () => ({
        isValid: false,
        isSubmitting: false,
        title: null,
        // terugkopIndiener: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
        dateFormatted: null,
        menu: false,
        project: null,
        assignedUser: null,
        problem: null,
        purpose: null,
        solution: null,
        secure: null,
        department: null,
        extraImages: null,
        extraFiles: null,
        image: null,
        requiredRules: [
            v => !!v || 'Dit veld is verplicht',
        ],
        departments: [
            'Administratie',
            'Algemeen',
            'Calculatie',
            'Engineering',
            'Kopersbeheer',
            'Management Team',
            'Materieeldienst',
            'Projectvoorbereiding',
            'Projectontwikkeling',
            'Secretariaat',
            'Uitvoering',
            'Werkgroep',
            'Personeelszaken',
            'Ondernemingsraad',
            'Personeelsvereniging',
            'Stuurgroep Newforma',
            'Werkgroep PSA',
            'Gereedschapscommissie',
            'Team BMJ',
        ],
    }),
    computed: {
        users () {
            let users = JSON.parse(JSON.stringify(this.$store.state.users.users));
            users.sort((a, b) => a.title.localeCompare(b.title));
            return users;
        },
        ...mapState({
            projects: state => state.projects.projects,
        }),
    },
    // watch: {
        // terugkopIndiener () {
        //     this.dateFormatted = this.formatDate(this.terugkopIndiener)
        // }
    // },
    mounted() {
        this.$store.dispatch('getProjects')
        this.$store.dispatch('getUsers')
    },
    methods: {
        submit() {
            if (!this.$refs.form.validate()) {
                return;
            }

            let formData = new FormData();
            formData.set('title', this.title);
            formData.set('project', this.project);
            formData.set('assignedUser', this.assignedUser);
            // formData.set('terugkopIndiener', this.terugkopIndiener)
            formData.set('problem', this.problem);
            formData.set('purpose', this.purpose);
            formData.set('solution', this.solution);
            formData.set('secure', this.secure);
            formData.set('department', this.department);
            formData.set('image', this.image);
            if (this.extraImages) {
                this.extraImages.forEach((image, index) => {
                    formData.set('extraImages['+index+']', image);
                });
            }
            if (this.extraFiles) {
                this.extraFiles.forEach((file, index) => {
                    formData.set('extraFiles['+index+']', file);
                });
            }

            this.isSubmitting = true;

            this.$store.dispatch('addImprovement', formData).then(improvement => {
                this.isSubmitting = false;
                this.title = null;
                this.project = null;
                this.assignedUser = null;
                // this.terugkopIndiener = null,
                this.problem = null;
                this.purpose = null;
                this.solution = null;
                this.secure = null;
                this.department = null;
                this.extraImages = null;
                this.extraFiles = null;
                this.$router.push({name: 'ImprovementDetail', params: {number: improvement.number}});
            });
        },
        formatDate (date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}-${month}-${year}`
        },
    },
}
</script>

<style scoped>

</style>
