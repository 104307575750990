<template>
    <v-container>
        <v-row>
            <v-col>
                <h1 class="text-h1">Verbeterplannen</h1>
            </v-col>
            <v-col class="text-right">
                <v-btn class="btn-decorative" :to="{name:'ImprovementSubmit'}" elevation="0">
                    <v-icon small class="mr-2">fal fa-plus</v-icon>
                    Nieuw verbetervoorstel
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-if="!isLoading">
            <v-col class="text-right d-sm-flex">
                <v-spacer></v-spacer>
                <v-select
                    v-model="readyFilter"
                    label="Gereed"
                    :items="[{
                        label: 'Alles',
                        value: null,
                    }, {
                        label: 'Ja',
                        value: true,
                    }, {
                        label: 'Nee',
                        value: false,
                    }]"
                    class="mr-sm-6 shrink"
                    item-text="label"
                    item-value="value"
                ></v-select>
                <v-select
                    v-if="reportedByDepartments.length > 0"
                    v-model="reportedByDepartmentsFilter"
                    label="Ingediend door"
                    :items="reportedByDepartments"
                    multiple
                    class="mr-sm-6 shrink"
                ></v-select>
                <v-select
                    v-if="departments.length > 0"
                    v-model="departmentsFilter"
                    label="Ondergebracht bij"
                    :items="departments"
                    multiple
                    class="mr-sm-6 shrink"
                ></v-select>
                <v-select
                    v-if="assignedUsers.length > 0"
                    v-model="assignedUsersFilter"
                    label="Ondergebracht bij persoon"
                    :items="assignedUsers"
                    item-text="title"
                    multiple
                    class="mr-sm-6 shrink"
                ></v-select>
                <v-select
                    v-model="typesFilter"
                    label="Type"
                    :items="['S', 'M', 'L']"
                    multiple
                    class="mr-sm-6 shrink"
                ></v-select>
                <v-text-field
                    v-model="search"
                    append-icon="fal fa-search"
                    label="Zoeken..."
                    single-line
                    hide-details
                    class="shrink"
                ></v-text-field>
            </v-col>
        </v-row>

        <v-card>
            <v-data-table
                :loading="isLoading"
                :headers="headers"
                :items="improvements"
                :search="search"
                :page.sync="page"
                item-key="number"
                show-expand
                class="elevation-1 improvements-table"
            >
                <template v-slot:item.title="{ item }">
                    <router-link :to="{name: 'ImprovementDetail', params: { number: item.number }}">{{ item.title }}
                    </router-link>
                </template>
                <template v-slot:item.ready="{ item }">
                    <span>{{ item.ready ? 'Ja' : 'Nee' }}</span>
                </template>
                <template v-slot:item.date="{ item }">
                    <span>{{ item.date|luxon('dd-MM-yyyy') }}</span>
                </template>
                <template v-slot:item.progress="{ item }">
                    <span>{{ item.progress }} %</span>
                </template>
                <template v-slot:item.terugkopIndiener="{ item }">
                    <span>{{ item.terugkopIndiener|luxon('dd-MM-yyyy') }}</span>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" style="white-space: pre-line;padding-top:15px;padding-bottom:15px;">{{ getFullItemRemarks(item) }}</td>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
import {mapState} from "vuex";
import { DateTime } from "luxon";

export default {
    name: "ImprovementList",
    mounted() {
        this.loadImprovements();

        this.readyFilter = localStorage.improvementReadyFilter ? localStorage.improvementReadyFilter === 'true' : null;
        this.reportedByDepartmentsFilter = localStorage.improvementReportedByDepartmentsFilter ? JSON.parse(localStorage.improvementReportedByDepartmentsFilter) : [];
        this.departmentsFilter = localStorage.improvementDepartmentsFilter ? JSON.parse(localStorage.improvementDepartmentsFilter) : [];
        this.assignedUsersFilter = localStorage.improvementAssignedUsersFilter ? JSON.parse(localStorage.improvementAssignedUsersFilter) : [];
        this.typesFilter = localStorage.improvementTypesFilter ? JSON.parse(localStorage.improvementTypesFilter) : [];
        this.search = localStorage.improvementSearch ? localStorage.improvementSearch : null;

        if (localStorage.pageContext === 'improvement') {
            this.page = parseInt(localStorage.page);
        }
        localStorage.page = this.page;
        localStorage.pageContext = 'improvement';
    },
    data: () => ({
        isLoading: true,
        readyFilter: null,
        reportedByDepartmentsFilter: [],
        departmentsFilter: [],
        assignedUsersFilter: [],
        typesFilter: [],
        search: null,
        page: 1,
    }),
    watch: {
        readyFilter () {
            localStorage.improvementReadyFilter = this.readyFilter;
        },
        reportedByDepartmentsFilter () {
            localStorage.improvementReportedByDepartmentsFilter = JSON.stringify(this.reportedByDepartmentsFilter);
        },
        departmentsFilter () {
            localStorage.improvementDepartmentsFilter = JSON.stringify(this.departmentsFilter);
        },
        assignedUsersFilter () {
            localStorage.improvementAssignedUsersFilter = JSON.stringify(this.assignedUsersFilter);
        },
        typesFilter () {
            localStorage.improvementTypesFilter = JSON.stringify(this.typesFilter);
        },
        search () {
            localStorage.improvementSearch = this.search;
        },
        page () {
            localStorage.page = this.page;
            localStorage.pageContext = 'improvement';
        },
    },
    computed: {
        ...mapState({
            improvements: state => state.improvements.improvements
        }),
        headers () {
            return [
                {text: 'Volgnummer', value: 'number'},
                {text: 'Verbeterplan', value: 'title'},
                {text: 'Ingediend door', value: 'createdBy.title'},
                {
                    text: 'Ingediend door afdeling',
                    value: 'reportedByDepartment',
                    filter: value => {
                        if (!this.reportedByDepartmentsFilter || this.reportedByDepartmentsFilter.length === 0) return true;

                        return this.reportedByDepartmentsFilter.includes(value);
                    },
                },
                {
                    text: 'Ondergebracht bij afdeling',
                    value: 'department',
                    filter: value => {
                        if (!this.departmentsFilter || this.departmentsFilter.length === 0) return true;

                        return this.departmentsFilter.includes(value);
                    },
                },
                {
                    text: 'Ondergebracht bij persoon',
                    value: 'assignedUser.title',
                    filter: value => {
                        if (!this.assignedUsersFilter || this.assignedUsersFilter.length === 0) return true;

                        return this.assignedUsersFilter.includes(value);
                    },
                },
                {
                    text: 'Gereed',
                    value: 'ready',
                    filter: value => {
                        if (this.readyFilter === null) {
                            return true;
                        }

                        return value === this.readyFilter;
                    },
                },
                {text: 'Datum melding', value: 'date'},
                {text: 'Voortgang', value: 'progress'},
                {text: 'Terugkop indiener', value: 'terugkopIndiener'},
                {
                    text: 'Type',
                    value: 'type',
                    filter: value => {
                        if (!this.typesFilter || this.typesFilter.length === 0) return true;

                        return this.typesFilter.includes(value);
                    },
                },
                {text: '', value: 'data-table-expand'},
            ];
        },
        reportedByDepartments () {
            let reportedByDepartments = [];
            this.improvements.forEach(improvement => {
                if (improvement.reportedByDepartment && !reportedByDepartments.includes(improvement.reportedByDepartment)) {
                    reportedByDepartments.push(improvement.reportedByDepartment);
                }
            });
            return reportedByDepartments.sort();
        },
        departments () {
            let departments = [];
            this.improvements.forEach(improvement => {
                if (improvement.department && !departments.includes(improvement.department)) {
                    departments.push(improvement.department);
                }
            });
            return departments.sort();
        },
        assignedUsers () {
            let assignedUsers = [];
            this.improvements.forEach(improvement => {
                if (improvement.assignedUser && !assignedUsers.includes(improvement.assignedUser)) {
                    assignedUsers.push(improvement.assignedUser);
                }
            });
            return assignedUsers.sort((a, b) => a.title.localeCompare(b.title));
        },
    },
    methods: {
        loadImprovements() {
            this.isLoading = true;
            this.$store.dispatch('getImprovements')
                .finally(() => {
                    this.isLoading = false
                })
        },
        getFullItemRemarks(item) {
            let remarks = item.remarks ? item.remarks : '';

            item.comments.forEach(comment => {
                if (remarks) {
                    remarks += '\n\n';
                }

                let user = comment.user ? comment.user.title : 'Onbekend';
                remarks += DateTime.fromISO(comment.createdAt).toFormat('yyyy-MM-dd H:m')+' - '+user+': '+comment.comment;
            });

            return remarks;
        },
    }
}
</script>

<style scoped>

</style>
