var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('h1',{staticClass:"text-h1"},[_vm._v("Verbeterplannen")])]),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"btn-decorative",attrs:{"to":{name:'ImprovementSubmit'},"elevation":"0"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("fal fa-plus")]),_vm._v(" Nieuw verbetervoorstel ")],1)],1)],1),(!_vm.isLoading)?_c('v-row',[_c('v-col',{staticClass:"text-right d-sm-flex"},[_c('v-spacer'),_c('v-select',{staticClass:"mr-sm-6 shrink",attrs:{"label":"Gereed","items":[{
                    label: 'Alles',
                    value: null,
                }, {
                    label: 'Ja',
                    value: true,
                }, {
                    label: 'Nee',
                    value: false,
                }],"item-text":"label","item-value":"value"},model:{value:(_vm.readyFilter),callback:function ($$v) {_vm.readyFilter=$$v},expression:"readyFilter"}}),(_vm.reportedByDepartments.length > 0)?_c('v-select',{staticClass:"mr-sm-6 shrink",attrs:{"label":"Ingediend door","items":_vm.reportedByDepartments,"multiple":""},model:{value:(_vm.reportedByDepartmentsFilter),callback:function ($$v) {_vm.reportedByDepartmentsFilter=$$v},expression:"reportedByDepartmentsFilter"}}):_vm._e(),(_vm.departments.length > 0)?_c('v-select',{staticClass:"mr-sm-6 shrink",attrs:{"label":"Ondergebracht bij","items":_vm.departments,"multiple":""},model:{value:(_vm.departmentsFilter),callback:function ($$v) {_vm.departmentsFilter=$$v},expression:"departmentsFilter"}}):_vm._e(),(_vm.assignedUsers.length > 0)?_c('v-select',{staticClass:"mr-sm-6 shrink",attrs:{"label":"Ondergebracht bij persoon","items":_vm.assignedUsers,"item-text":"title","multiple":""},model:{value:(_vm.assignedUsersFilter),callback:function ($$v) {_vm.assignedUsersFilter=$$v},expression:"assignedUsersFilter"}}):_vm._e(),_c('v-select',{staticClass:"mr-sm-6 shrink",attrs:{"label":"Type","items":['S', 'M', 'L'],"multiple":""},model:{value:(_vm.typesFilter),callback:function ($$v) {_vm.typesFilter=$$v},expression:"typesFilter"}}),_c('v-text-field',{staticClass:"shrink",attrs:{"append-icon":"fal fa-search","label":"Zoeken...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1):_vm._e(),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1 improvements-table",attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.improvements,"search":_vm.search,"page":_vm.page,"item-key":"number","show-expand":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: 'ImprovementDetail', params: { number: item.number }}}},[_vm._v(_vm._s(item.title)+" ")])]}},{key:"item.ready",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.ready ? 'Ja' : 'Nee'))])]}},{key:"item.date",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("luxon")(item.date,'dd-MM-yyyy')))])]}},{key:"item.progress",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.progress)+" %")])]}},{key:"item.terugkopIndiener",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("luxon")(item.terugkopIndiener,'dd-MM-yyyy')))])]}},{key:"expanded-item",fn:function(ref){
                var headers = ref.headers;
                var item = ref.item;
return [_c('td',{staticStyle:{"white-space":"pre-line","padding-top":"15px","padding-bottom":"15px"},attrs:{"colspan":headers.length}},[_vm._v(_vm._s(_vm.getFullItemRemarks(item)))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }